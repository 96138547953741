<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/selective_soldering/versaflowone">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/Ersa-VERSAFLOW-ONE-900x675.png"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">VERSAFLOW ONE</h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                  Your entry into the excellence class of selective soldering!
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/selective_soldering/versaflow4_55">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/Ersa-VERSAFLOW455-01-900x675.png"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
VERSAFLOW 4/55</h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
The Non-Plus-Ultra in Selective Soldering - Inline Selective Soldering now even…                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/selective_soldering/versaflow4_xl">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/Ersa-VERSAFLOW4XL-01-900x675.png"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">VERSAFLOW 4 XL</h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                  Revolutionary variety and flexibility in selective soldering
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/selective_soldering/versaflux">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/csm_Ersa-Selective-VERSAFLEX-01-900x675_95c62de2d5.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title"> VERSAFLUX & VERSAFLUX</h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                 Individual adjustment of flux heads and solder pots in X/Y/Z.
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/selective_soldering/versaflow3_66xl">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/Ersa-VERSAFLOW366XL-01-900x675.png"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">VERSAFLOW 3/66 XL</h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                  Modular machine platform for processing PCBs with maximum length of 3,000 mm
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/selective_soldering/versaflow3_66">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/Ersa-VERSAFLOW366-01-900x675.png"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">VERSAFLOW 3/66</h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                  Modularity for highly flexible selective soldering of XL boards.
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/selective_soldering/versaflow3_45">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/Ersa-VERSAFLOW345-01-900x675.png"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">VERSAFLOW 3/45</h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                 The first inline selective system with double transport.
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/selective_soldering/versaflow3_35">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/Ersa-VERSAFLOW335-GLOBAL-5572-900x675.png"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">VERSAFLOW 3/35 GLOBAL EDITION</h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                 Configured soldering machines with the world´s leading selective technology
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
       
      </div>

       <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/selective_soldering/smartflow2020">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/Ersa-SMARTFLOW2020-01-900x675.png"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">SMARTFLOW 2020</h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                  The perfect start-up solution
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/selective_soldering/ecoselect4">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/csm_Ersa-ECOSELECT4-01-900x675_115ce2cb97.png"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">ECOSELECT 4</h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                  Inline and batch selective soldering system for the highest quality
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/ecoselect1">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/csm_Ersa-ECOSELECT1-01-900x675_7504332b26.png"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">ECOSELECT 1</h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                 Small Selective Soldering System with outstanding technology.
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/selective_soldering/powerflux">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/csm_Ersa-POWERFLUX-01-900x675_bd591981e9.png"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">POWERFLUX</h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                  Best selective process safety even with highest throughput.
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
       
      </div>
    <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/selective_soldering/versaeye">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/csm_Ersa-Selective-VERSAEYE-01-900x675_8336aa5341.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">VERSAEYE</h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                  Perfectly documented solder joint quality especially for the inspection of THT-solder joints.
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/selective_soldering/cad_assistent4">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/csm_Ersa-Selective-CAD-Assistant-4-01-900x675_b17efdfbf7.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">CAD-Assistent 4</h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                  Energy-efficient reflow system for maximum throughput
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
   
       
       
      </div>


      
    
      
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>